/*----------- Breakpoints -------------*/
/*----------- Animations -------------*/
*,
*::before,
*::after {
  margin: initial;
  box-sizing: border-box; }

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  font-size: 18px;
  background-color: black;
  overflow-x: hidden; }

ul {
  padding: initial; }

li {
  list-style: none; }

@media (max-width: 767px) {
  h1, h2, h3, h4, h5, h6, li, span, b, p, a {
    letter-spacing: 0; } }

h1, h2, h3 {
  font-family: Oi, "cursive";
  font-style: normal;
  font-weight: normal;
  line-height: 1.3em; }

h4, h5 {
  font-family: Nunito, "sans-serif";
  font-style: normal;
  line-height: 1.3em; }

h1 {
  font-size: 70px; }
  @media (min-width: 2000px) {
    h1 {
      font-size: 130px; } }
  @media (max-width: 800px) {
    h1 {
      font-size: 45px; } }

h2 {
  font-size: 40px; }

h3 {
  font-size: 30px; }

h4 {
  font-weight: 800;
  font-size: 30px; }

h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.7em; }

p {
  font-family: Nunito, "sans-serif";
  font-style: normal;
  font-size: 22px;
  line-height: 1.7em; }
  @media (max-width: 800px) {
    p {
      font-size: 18px; } }

.description, .description p {
  font-family: Nunito, "sans-serif";
  font-size: 18px;
  line-height: 27px;
  color: #525252; }

.paragraph-l {
  font-size: 22px; }
  @media (min-width: 2000px) {
    .paragraph-l {
      font-size: 30px; } }
  @media (max-width: 800px) {
    .paragraph-l {
      font-size: 20px; } }

.inner-container {
  position: relative;
  max-width: 1200px;
  height: 100%;
  margin: auto; }
  @media (min-width: 2000px) {
    .inner-container {
      max-width: 65%; } }
  @media (max-width: 1199px) {
    .inner-container {
      max-width: 90%; } }
  @media (max-width: 991px) {
    .inner-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 0 25px;
      max-width: initial; } }

.container {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px; }
  @media (max-width: 767px) {
    .container {
      padding: 0 25px; } }

@media (max-width: 767px) {
  .hide-on-mobile {
    display: none !important; } }

.show-on-mobile {
  display: none !important; }
  @media (max-width: 767px) {
    .show-on-mobile {
      display: block !important; } }

.button {
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  padding: 15px 20px;
  font-family: Nunito, "sans-serif";
  font-size: 14px;
  font-weight: 900;
  cursor: pointer;
  transition: all .2s; }
  @media (max-width: 991px) {
    .button {
      padding: 15px 10px; } }
  .button--pink {
    background-color: #F13764;
    color: #FFFFFF; }
    .button--pink:hover {
      background-color: #D42A53; }
  .button--green {
    background-color: #5CD67E;
    color: white; }
    .button--green:hover {
      background-color: #8FEFAA; }
  .button--white {
    background-color: white; }
    .button--white:hover {
      border: 2px solid #FFFFFF;
      background-color: transparent;
      color: #FFFFFF !important; }
      .button--white:hover .button__icon {
        color: #FFFFFF !important; }
  .button__icon {
    width: 16px;
    margin-left: 10px; }
  .button--file {
    font-size: 16px;
    line-height: 24px;
    background-color: #5CD67E;
    font-family: Nunito, "sans-serif";
    color: #FFFFFF;
    text-decoration: initial;
    padding: 16px 20px;
    font-weight: 900; }
    .button--file img {
      margin-left: 10px; }
    .button--file:hover {
      background-color: #42B462; }

.title {
  font-size: 30px;
  font-family: Oi, "cursive";
  font-style: normal;
  font-weight: normal;
  line-height: 1.3em;
  color: #414141; }

.comic-card {
  position: relative;
  width: auto;
  transition: transform .3s ease-in;
  cursor: pointer;
  padding: 0 15px;
  gap: 55px;
  display: grid;
  grid-template-columns: 1fr; }
  .comic-card:hover {
    transform: scale(1.05); }
    .comic-card:hover .comic-card__play-icon {
      opacity: 1; }
  .comic-card__icon {
    position: absolute;
    top: 40px;
    right: 35px; }
  .comic-card__play-icon {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    opacity: 0;
    transition: opacity	 .3s ease-in; }
  .comic-card__img-container {
    position: relative; }
  .comic-card__bottom {
    width: 90%;
    height: 10px;
    background-color: #D1D1D1;
    border-radius: 0 0 15px 15px;
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1; }
    .comic-card__bottom--secondary {
      background-color: #DFDFDF;
      bottom: -18px;
      width: 80%; }
    .comic-card__bottom--tertiary {
      background-color: #EDEDED;
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
      bottom: -27px;
      width: 70%;
      z-index: -3; }
  .comic-card__text-container {
    text-align: center; }
  .comic-card__title {
    font-family: Nunito, "sans-serif";
    font-weight: 800;
    font-size: 30px;
    line-height: 39px;
    color: #414141;
    margin-bottom: 15px; }
  .comic-card__image {
    width: 100%;
    height: 380px;
    object-fit: cover;
    background-position: center;
    border-radius: 25px; }
    @media (max-width: 1079px) {
      .comic-card__image {
        height: 300px; } }
    @media (max-width: 767px) {
      .comic-card__image {
        height: 270px; } }
  .comic-card__copy {
    font-size: 18px;
    line-height: 27px;
    color: #8B8B8B;
    max-width: 330px;
    margin: 0 auto; }

.main-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow-x: hidden; }
  @media (max-width: 991px) {
    .main-container {
      min-height: 100vh; } }
  .main-container__left {
    position: absolute;
    left: 0;
    height: 100%;
    width: 30%; }
    @media (max-width: 991px) {
      .main-container__left {
        right: 0;
        height: 34%;
        width: 100%; } }
    @media (max-width: 767px) {
      .main-container__left {
        height: 45%; } }
  .main-container__right {
    position: absolute;
    left: 26%;
    height: 100%;
    width: 76%; }
    .main-container__right--enter {
      width: 0; }
    .main-container__right--enter-active {
      width: 86%; }
    .main-container__right--exit-active {
      width: 0;
      transition: all 1s;
      transition-delay: .1s; }
    @media (max-width: 991px) {
      .main-container__right {
        left: 0;
        right: 0;
        bottom: 0;
        height: 67%;
        width: 100%; }
        .main-container__right--enter-active {
          width: 100%; }
        .main-container__right--exit-active {
          transform: translateX(-800px);
          transition: transform 1s;
          transition-delay: .1s; } }
    @media (max-width: 767px) {
      .main-container__right {
        height: 56%; } }
  .main-container--enter {
    opacity: 0; }
  .main-container--enter-active {
    animation-name: pulse;
    animation-duration: .8s;
    animation-fill-mode: both;
    opacity: 1;
    transition: opacity 1s; }
  .main-container.no-overflow {
    overflow: hidden; }
    @media (max-width: 767px) {
      .main-container.no-overflow {
        height: auto; } }
    @media (max-width: 767px) {
      .main-container.no-overflow .main-page__tab:after {
        bottom: -100px; } }

.animated {
  animation-duration: 2.5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite; }

@keyframes pulse {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

@keyframes rubberBand {
  0% {
    transform: scale(1); }
  30% {
    transform: scaleX(1.25) scaleY(0.75); }
  40% {
    transform: scaleX(0.75) scaleY(1.25); }
  60% {
    transform: scaleX(1.15) scaleY(0.85); }
  100% {
    transform: scale(1); } }

.main-page {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  padding-top: 130px; }
  @media (max-width: 991px) {
    .main-page {
      position: relative;
      height: inherit; } }
  .main-page__tab {
    position: relative;
    z-index: 1; }
    @media (max-width: 991px) {
      .main-page__tab {
        display: flex;
        justify-content: center; } }
    .main-page__tab:after {
      content: '';
      bottom: -39px;
      left: 25px;
      display: block;
      width: 20px;
      height: 20px;
      transform: rotate(180deg);
      position: absolute;
      width: 0;
      height: 0;
      border-right: 20px solid transparent;
      border-top: 20px solid transparent;
      border-left: 20px solid transparent;
      border-bottom: 20px solid transparent; }
      @media (max-width: 1079px) {
        .main-page__tab:after {
          left: initial;
          bottom: -38px; } }
  .main-page__content {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #F8F8F8;
    transform: initial; }
    .main-page__content--enter-active, .main-page__content--enter-done, .main-page__content--exit {
      transform: translateY(-55vh);
      transition: all .5s; }
      @media (max-width: 991px) {
        .main-page__content--enter-active, .main-page__content--enter-done, .main-page__content--exit {
          transform: translateY(-73vh); } }
      @media (max-width: 767px) {
        .main-page__content--enter-active, .main-page__content--enter-done, .main-page__content--exit {
          transform: translateY(-400px); } }
      @media (max-width: 320px) {
        .main-page__content--enter-active, .main-page__content--enter-done, .main-page__content--exit {
          transform: translateY(-323px); } }
    .main-page__content--enter-done {
      position: relative; }
    .main-page__content--exit-active {
      transform: translateY(1px);
      opacity: 0;
      transition: all .5s; }

.navbar {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1250px;
  margin: auto;
  padding: 52px 25px 0 25px;
  z-index: 99; }
  @media (min-width: 2000px) {
    .navbar {
      max-width: 65%; } }
  @media (max-width: 991px) {
    .navbar {
      padding: 25px 25px 0 25px; } }
  .navbar.active {
    position: fixed;
    height: 100vh;
    flex-direction: column;
    transition: all .5s; }
    @media (max-width: 767px) {
      .navbar.active {
        overflow-y: scroll; } }
    .navbar.active .navbar__list-container {
      animation-name: navbarMobile;
      animation-duration: .5s;
      animation-fill-mode: forwards; }
  .navbar__logo {
    width: 107px;
    position: relative; }
    @media (max-width: 991px) {
      .navbar__logo {
        align-self: flex-start; } }

@keyframes wink {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }
    .navbar__logo::after {
      position: absolute;
      width: 23px;
      height: 23px;
      border-radius: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 123 140' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M46.3181 138.312C51.2709 139.168 56.1932 139.596 61.0849 139.596C65.9765 139.596 70.8988 139.168 75.8516 138.312C80.8044 137.456 85.5433 135.989 90.0681 133.91C94.5929 131.831 98.812 129.079 102.725 125.655C106.639 122.17 110.032 117.859 112.906 112.723C115.78 107.525 118.042 101.38 119.693 94.2872C121.344 87.1942 122.17 79.0006 122.17 69.7065C122.17 60.4123 121.344 52.2187 119.693 45.1258C118.042 38.0328 115.78 31.9182 112.906 26.782C110.032 21.5845 106.639 17.2737 102.725 13.8496C98.812 10.4254 94.5929 7.7044 90.0681 5.68658C85.5433 3.60761 80.8044 2.14011 75.8516 1.28407C70.8988 0.428022 65.9765 0 61.0849 0C56.1932 0 51.2709 0.428022 46.3181 1.28407C41.4264 2.14011 36.7182 3.60761 32.1934 5.68658C27.6686 7.7044 23.4495 10.4254 19.5361 13.8496C15.6228 17.2737 12.1986 21.5845 9.26361 26.782C6.38975 31.9182 4.12735 38.0328 2.47641 45.1258C0.825468 52.2187 0 60.4123 0 69.7065C0 79.0618 0.825468 87.2859 2.47641 94.3789C4.12735 101.411 6.38975 107.525 9.26361 112.723C12.1986 117.859 15.6228 122.17 19.5361 125.655C23.4495 129.079 27.6686 131.831 32.1934 133.91C36.7182 135.989 41.4264 137.456 46.3181 138.312ZM105.704 48.401C97.4456 47.8728 78.5036 50.9759 69.1987 68.4312C68.3569 70.0104 68.5308 71.9383 69.6417 73.3414C70.7525 74.7444 72.5892 75.3559 74.3194 74.8987C77.4493 74.0716 82.9252 73.2691 88.7281 73.6941C94.5678 74.1219 100.192 75.7488 104.221 79.2441C106.098 80.8727 108.94 80.671 110.569 78.7937C112.197 76.9164 111.996 74.0743 110.119 72.4457C104.053 67.1835 96.2403 65.2203 89.3856 64.7182C87.4305 64.575 85.5088 64.5471 83.6676 64.6047C90.9847 58.4646 100.311 57.0744 105.13 57.3827C107.61 57.5413 109.749 55.6593 109.908 53.1791C110.067 50.6989 108.185 48.5597 105.704 48.401Z' fill='white'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      right: 55px;
      top: 9px;
      opacity: 0; }
    .navbar__logo:hover::after {
      animation: wink 0.7s both; }
  .navbar__list-container {
    display: flex;
    align-items: center; }
    @media (max-width: 991px) {
      .navbar__list-container {
        position: absolute;
        transform: translateY(-100vh);
        flex-direction: column;
        width: 100%;
        font-size: 24px;
        left: 0; } }
  .navbar__list {
    display: flex; }
    @media (max-width: 991px) {
      .navbar__list {
        flex-direction: column;
        text-align: center;
        width: 100%; } }
  .navbar__item {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 1199px) {
      .navbar__item:last-of-type {
        max-width: 124px;
        margin: 50px auto !important; } }
    .navbar__item:last-of-type .navbar__link {
      min-width: 125px;
      padding: 15px 20px;
      width: initial;
      background-color: #F13764;
      font-size: 14px;
      margin-right: initial;
      display: flex;
      align-items: center; }
      .navbar__item:last-of-type .navbar__link svg {
        margin-left: 10px;
        font-size: 18px; }
      .navbar__item:last-of-type .navbar__link:after {
        display: none; }
      .navbar__item:last-of-type .navbar__link:hover {
        background-color: #FF90AB; }
    @media (max-width: 991px) {
      .navbar__item {
        width: 100%;
        height: 53px; } }
  .navbar__link {
    position: relative;
    margin-right: 30px;
    font-family: Nunito, "sans-serif";
    font-size: 16px;
    font-weight: 900;
    text-decoration: none;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease; }
    @media (max-width: 991px) {
      .navbar__link {
        margin: 0;
        font-size: inherit; } }
    .navbar__link:hover {
      transform: scale3d(1.1, 1.1, 1.1); }
    .navbar__link--active:after {
      content: '';
      height: 2px;
      width: 25px;
      background: #FFFFFF;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      bottom: -6px; }
      @media (max-width: 991px) {
        .navbar__link--active:after {
          content: none; } }
    @media (max-width: 991px) {
      .navbar__link--active {
        width: 100%;
        padding: 10px 0;
        background-color: rgba(255, 255, 255, 0.2); } }
  .navbar__hamburger {
    display: none;
    width: 42px;
    height: 42px;
    border: 3.5px solid white;
    border-radius: 50%;
    cursor: pointer;
    font-size: 21px;
    color: white; }
    @media (max-width: 991px) {
      .navbar__hamburger {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 25px;
        right: 25px;
        z-index: 10; } }
  .navbar .button {
    color: white !important;
    font-size: inherit; }
    @media (max-width: 991px) {
      .navbar .button {
        margin: 50px 0; } }
    .navbar .button__icon {
      color: white !important; }
  .navbar .social-media {
    position: relative;
    bottom: 0; }
    .navbar .social-media__icon {
      font-size: 30px; }

@keyframes navbarMobile {
  from {
    transform: translateY(-100vh); }
  to {
    transform: translateY(15%); } }

.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 140px;
  z-index: 3;
  opacity: 1;
  transition: opacity .5s ease; }
  @media (max-width: 991px) {
    .slider {
      justify-content: flex-end;
      width: -moz-fit-content;
      width: fit-content;
      left: 50%;
      transform: translateX(-50%); } }
  .slider__options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px; }
  .slider__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    background-color: transparent;
    font-size: 20px;
    font-weight: 800;
    color: #FFFFFF;
    cursor: pointer;
    outline: none; }
    @media (max-width: 767px) {
      .slider__arrow {
        display: none; } }
  .slider__arrow:hover, .slider__arrow:active {
    border: 2px solid #FFFFFF; }
  .slider__arrow:hover {
    background: #FFFFFF;
    color: #525252; }
  .slider__slide-link {
    width: 100%; }

@keyframes options {
  0% {
    top: -20px; }
  100% {
    top: 0px; } }
  .slider__option {
    cursor: pointer;
    height: 15px;
    width: 15px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    display: inline-block;
    position: relative; }
    .slider__option:nth-of-type(1) {
      margin-left: 20px; }
    .slider__option:last-of-type {
      margin-right: 20px; }
    .slider__option:not(:nth-of-type(1)) {
      margin-left: 10px; }
    .slider__option--active {
      background-color: white; }
  .slider .active, .slider__option:hover {
    background-color: rgba(255, 255, 255, 0.7); }
  .slider__slide-container {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 25px 25px 0 0;
    height: 90px;
    width: -webkit-fill-available;
    width: -moz-available;
    padding-right: 21px;
    overflow: hidden; }
    @media (max-width: 991px) {
      .slider__slide-container {
        display: none; } }
    .slider__slide-container--appear {
      bottom: -100px; }
    .slider__slide-container--appear-active {
      bottom: 0;
      transition: all .5s; }
    .slider__slide-container--enter {
      bottom: -100px; }
    .slider__slide-container--enter-active {
      bottom: 0;
      transition: all .5s;
      transition-delay: .5s; }
    .slider__slide-container--exit-active {
      bottom: -100px;
      transition: all .5s; }
  .slider__title {
    width: 110px;
    text-align: right;
    font-family: Nunito, "sans-serif";
    font-size: 16px;
    font-weight: 800;
    color: #FFFFFF; }
  .slider__character-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    left: -16%;
    width: 153px;
    height: 153px;
    padding: 12px 30px 0 25px;
    border-radius: 50%; }
  .slider__character {
    width: 75px;
    margin-bottom: 18px; }

.videos-grid {
  padding: 87px 0 100px;
  position: relative;
  overflow: hidden;
  background-color: #FFFFFF; }
  @media (max-width: 767px) {
    .videos-grid {
      padding: 50px 0; } }
  .videos-grid__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 45px 33px;
    margin-bottom: 75px; }
    @media (max-width: 991px) {
      .videos-grid__grid {
        grid-template-columns: repeat(2, 1fr); } }
    @media (max-width: 767px) {
      .videos-grid__grid {
        grid-template-columns: 1fr; } }
  .videos-grid__slider {
    display: none; }
    .videos-grid__slider .slick-slide {
      padding: 0 4px; }
  .videos-grid__background {
    position: absolute;
    width: 100%;
    object-fit: contain;
    top: 125px;
    right: 0; }

.video {
  z-index: 1; }
  @media (min-width: 768px) {
    .video--with-icon {
      display: flex;
      flex-direction: column-reverse; } }
  .video--with-icon .video__title-container {
    display: grid;
    grid-template-columns: 30px 1fr;
    gap: 0 15px;
    max-width: max-content; }
    @media (min-width: 768px) {
      .video--with-icon .video__title-container {
        margin-left: initial;
        margin-bottom: 22px; } }
  @media (min-width: 768px) {
    .video--with-icon .video__title {
      font-size: 30px; } }
  @media (min-width: 992px) {
    .video--with-icon .video__content {
      margin-bottom: initial;
      max-height: 332px;
      min-height: 332px; } }
  @media (min-width: 768px) {
    .video--with-icon .video__timer {
      display: none; } }
  .video__content {
    width: 100%;
    max-height: 245px;
    min-height: 245px;
    overflow: hidden;
    border-radius: 25px;
    position: relative;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    cursor: pointer; }
    @media (max-width: 767px) {
      .video__content {
        max-height: 210px;
        min-height: 210px; } }
    .video__content.placeholder {
      background-color: rgba(0, 0, 0, 0.15); }
    .video__content:hover .video__icon {
      transform: scale(1.11); }
  .video__placeholder {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center; }
  .video__icon {
    max-width: 70px;
    width: 100%;
    position: absolute;
    transition: all 0.3s ease; }
  .video__timer {
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    font-weight: bold;
    position: absolute;
    right: 27px;
    bottom: 22px;
    padding: 7px 24px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 10px; }
  .video__title-container {
    margin: 0 auto; }
  .video__title {
    font-size: 24px;
    line-height: 32px;
    color: #5042BA;
    font-weight: bold;
    text-align: center; }
    .video__title--green {
      color: #5CD67E; }
    .video__title--black {
      color: #414141; }
    .video__title--orange {
      color: #FC8B4C; }

.social-media {
  z-index: 10;
  display: flex;
  gap: 27px; }
  @media (max-width: 767px) {
    .social-media {
      justify-content: center; } }
  .social-media__list {
    display: flex;
    align-items: center; }
    @media (max-width: 767px) {
      .social-media__list {
        justify-content: center;
        gap: 20px; } }
  .social-media__item {
    margin-right: 20px; }
    @media (min-width: 2000px) {
      .social-media__item {
        font-size: 25px; } }
    @media (max-width: 767px) {
      .social-media__item {
        margin: 0; } }
  .social-media__icon {
    font-size: 21px;
    color: white;
    opacity: .5;
    max-height: 23px; }
    @media (min-width: 2000px) {
      .social-media__icon {
        font-size: 40px; } }
  .social-media__icon:hover {
    opacity: 1; }

.pagination {
  z-index: 1;
  position: relative;
  display: flex; }
  @media (max-width: 767px) {
    .pagination--hide-on-mobile {
      display: none; } }
  .pagination--green .pagination__index.active {
    background-color: #5CD67E; }
  .pagination img {
    cursor: pointer;
    margin-left: 10px;
    display: inline-block; }
  .pagination__index {
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
    border-radius: 50%;
    font-weight: normal;
    color: #757575;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; }
    .pagination__index.active {
      color: #FFFFFF;
      font-weight: bold;
      background-color: #5042BA; }
    .pagination__index p {
      font-size: 26px;
      align-self: center; }

.comic-slider {
  padding: 63px 0 75px; }
  @media (max-width: 1439px) {
    .comic-slider__container {
      padding: 0 70px; } }
  @media (max-width: 991px) {
    .comic-slider__container {
      padding: 0 25px; } }
  .comic-slider__container .slick-slider .slick-list {
    padding: 25px 0; }
  .comic-slider__container .slick-slider .slick-arrow {
    width: 45px;
    height: 45px;
    top: 38%;
    color: #525252;
    border: 2px solid rgba(82, 82, 82, 0.25);
    border-radius: 100%;
    font-size: 24px; }
    .comic-slider__container .slick-slider .slick-arrow:before {
      display: none; }
    .comic-slider__container .slick-slider .slick-arrow:hover {
      background: #525252; }
      .comic-slider__container .slick-slider .slick-arrow:hover .slick-arrows {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(33deg) brightness(105%) contrast(105%); }
  .comic-slider__container .slick-slider .slick-next {
    right: -55px; }
  .comic-slider__container .slick-slider .slick-prev {
    left: -55px; }

.slider-stack {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  opacity: 0;
  transition: all .2s ease-in;
  z-index: -1; }
  .slider-stack--appear-done {
    opacity: 1;
    z-index: 99; }
  .slider-stack__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65); }
  .slider-stack__slides {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
  .slider-stack__slide {
    max-width: 68%;
    position: absolute;
    width: 100%;
    transition: all .3s ease-in;
    transform: translateX(100vw); }
    .slider-stack__slide:first-child {
      transform: initial; }
    .slider-stack__slide--display:first-child {
      display: none; }
    @media screen and (max-width: 1500px) {
      .slider-stack__slide {
        max-width: 80%; } }
    @media (max-width: 767px) {
      .slider-stack__slide {
        max-width: 100%; } }
  .slider-stack__slide-img {
    width: 100%;
    border-radius: 20px; }
    @media (max-width: 991px) {
      .slider-stack__slide-img {
        border-radius: 8px; } }
  .slider-stack__close {
    top: -30px;
    right: -20px;
    position: absolute;
    cursor: pointer; }
    @media (max-width: 767px) {
      .slider-stack__close {
        right: 0; } }
  .slider-stack__dots-container {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .slider-stack__dot {
    background-color: #FFFFFF;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    margin: 0 4px;
    opacity: .4;
    cursor: pointer;
    transition: all .3s ease-in; }
    .slider-stack__dot.active {
      opacity: 1; }
  .slider-stack__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .slider-stack__arrow--left {
      left: 45px; }
      @media (max-width: 991px) {
        .slider-stack__arrow--left {
          left: 20px; } }
    .slider-stack__arrow--right {
      right: 45px; }
      @media (max-width: 991px) {
        .slider-stack__arrow--right {
          right: 20px; } }

.left-right {
  background-color: #F8F8F8;
  padding: 45px 0 70px; }
  @media (max-width: 767px) {
    .left-right {
      padding: 50px 0; } }
  .left-right__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 0 18px; }
    @media (max-width: 767px) {
      .left-right__container {
        gap: initial;
        grid-template-columns: initial; } }
  @media (max-width: 767px) {
    .left-right__left-side {
      text-align: center; } }
  .left-right__title-container {
    margin-bottom: 12px; }
    @media (max-width: 767px) {
      .left-right__title-container {
        margin-bottom: 25px; } }
  .left-right__description {
    margin-bottom: 43px;
    max-width: 530px; }
    @media (max-width: 767px) {
      .left-right__description {
        max-width: initial; } }
  .left-right__icons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 22px; }
    @media (max-width: 767px) {
      .left-right__icons-container {
        gap: 18px;
        justify-content: center; } }
  .left-right__icon-box {
    width: 120px;
    height: 120px;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 1079px) {
      .left-right__icon-box {
        width: 100px;
        height: 100px; } }
    @media (max-width: 767px) {
      .left-right__icon-box {
        width: 95px;
        height: 95px; } }
  .left-right__img {
    max-width: 600px;
    width: 100%; }
    @media (max-width: 767px) {
      .left-right__img {
        display: none; } }

.activity-card {
  background-color: #FFFFFF;
  border-radius: 25px;
  text-align: center;
  padding: 40px 20px 50px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  transition: all 0.3s ease; }
  .activity-card:hover {
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1); }
  @media (max-width: 767px) {
    .activity-card {
      margin-bottom: 30px;
      padding: 40px 17px 50px; } }
  .activity-card__title {
    color: #5CD67E;
    font-family: Nunito, "sans-serif";
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
    text-align: center;
    width: 100%; }
  .activity-card__img-container {
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    width: 100%;
    align-self: flex-end; }
  .activity-card__search-icon {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    opacity: 0;
    transition: all 0.3s ease;
    z-index: 2; }
  .activity-card__img {
    min-height: 190px;
    max-height: 190px;
    width: 100%;
    object-fit: cover;
    background-position: center;
    vertical-align: top; }
  .activity-card__cta {
    text-decoration: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -25px;
    right: 50%;
    transform: translateX(50%); }

.activities {
  background-color: #F8F8F8;
  padding: 60px 0 30px; }
  @media (max-width: 767px) {
    .activities {
      padding: 50px 0 20px; } }
  .activities__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 57px 30px;
    margin-bottom: 77px; }
    @media (max-width: 991px) {
      .activities__grid {
        grid-template-columns: repeat(2, 1fr); } }
    @media (max-width: 767px) {
      .activities__grid {
        display: none; } }
  .activities__modal {
    width: 100%;
    height: 100%;
    position: relative; }
  .activities__modal-img {
    width: 100%; }
  .activities__modal-cta {
    position: absolute;
    bottom: -80px;
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center; }
  .activities__slider {
    display: none; }
    @media (max-width: 767px) {
      .activities__slider {
        display: block; } }
    .activities__slider .slick-slide {
      padding: 0 4px; }

/* Modal */
.ReactModalPortal > div {
  opacity: 0; }

.ReactModal__Content {
  max-width: 750px;
  padding: 30px 40px;
  background: #FFFFFF;
  border-radius: 15px;
  position: relative; }
  @media (max-width: 991px) {
    .ReactModal__Content {
      width: 90%;
      padding: 20px 30px; } }

.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.75);
  transition: opacity .2s ease-in-out;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0 16px; }

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0; }

.modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 43px;
  border-radius: 100px;
  border: none;
  position: absolute;
  right: -15px;
  top: -15px;
  cursor: pointer;
  transition: all 0.3s ease; }
  .modal__close:hover {
    transform: scale(1.12); }

.modal-background {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed; }

.modal-video-close-btn {
  background-color: #D5D5D5 !important;
  border-radius: 50%;
  width: 43px !important;
  height: 43px !important;
  cursor: pointer;
  display: flex !important;
  top: -24px !important;
  right: -24px !important;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 991px) {
    .modal-video-close-btn {
      right: 0 !important; } }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: #414141 !important;
    width: 48% !important;
    height: 5px !important;
    margin-top: initial !important;
    left: initial !important;
    top: initial !important; }
  .modal-video-close-btn:hover {
    transform: scale(1.12); }

.custom-slick-dots {
  position: relative !important;
  bottom: initial !important;
  margin-top: 24px !important; }
  .custom-slick-dots li button {
    width: 17px !important;
    height: 17px !important;
    border-radius: 50%;
    color: initial !important;
    opacity: .25; }
    .custom-slick-dots li button:before {
      display: none; }
  .custom-slick-dots li.slick-active button {
    opacity: 1; }
  .custom-slick-dots--green li button {
    background-color: #5CD67E !important; }
  .custom-slick-dots--red li button {
    background-color: #F13764 !important; }
  .custom-slick-dots--black li button {
    background-color: #525252 !important; }

.slick-arrows--prev {
  transform: rotate(180deg); }

.custom-slick-arrows.slick-next, .custom-slick-arrows.slick-prev {
  width: 32px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  color: #525252;
  font-size: 16px;
  border-radius: 50%; }
  .custom-slick-arrows.slick-next:before, .custom-slick-arrows.slick-next:after, .custom-slick-arrows.slick-prev:before, .custom-slick-arrows.slick-prev:after {
    display: none; }
  .custom-slick-arrows.slick-next:hover, .custom-slick-arrows.slick-prev:hover {
    background: #525252; }
    .custom-slick-arrows.slick-next:hover .slick-arrows, .custom-slick-arrows.slick-prev:hover .slick-arrows {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(33deg) brightness(105%) contrast(105%); }
  @media (max-width: 767px) {
    .custom-slick-arrows.slick-next, .custom-slick-arrows.slick-prev {
      font-size: 13px;
      width: 21px;
      height: 21px; } }

.custom-slick-arrows.slick-next {
  right: -50px; }
  @media (max-width: 767px) {
    .custom-slick-arrows.slick-next {
      right: -25px; } }

.custom-slick-arrows.slick-prev {
  left: -50px; }
  @media (max-width: 767px) {
    .custom-slick-arrows.slick-prev {
      left: -25px; } }

.videos-tv-grid {
  background-color: #F8F8F8;
  padding: 77px 0 100px; }
  @media (max-width: 767px) {
    .videos-tv-grid {
      padding: 42px 0 62px; } }
  .videos-tv-grid__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px 20px; }
    @media (max-width: 991px) {
      .videos-tv-grid__grid {
        gap: 42px 0;
        grid-template-columns: 1fr; } }

.games-grid {
  background-color: #F8F8F8;
  padding: 88px 0; }
  @media (max-width: 767px) {
    .games-grid {
      padding: 51px 0; } }
  .games-grid__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 55px 20px; }
    @media (max-width: 767px) {
      .games-grid__grid {
        grid-template-columns: 1fr; } }

.game-card {
  text-align: center;
  border-radius: 15px;
  position: relative;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; }
  .game-card:hover {
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1); }
    .game-card:hover .game-card__thumbnail--hover {
      opacity: 1; }
    .game-card:hover .game-card__copy-hover {
      opacity: 1; }
  @media (max-width: 991px) {
    .game-card {
      padding: 30px 17px 60px; } }
  .game-card__title {
    font-size: 40px;
    line-height: 63px;
    color: #F13764; }
    @media (max-width: 1079px) {
      .game-card__title {
        font-size: 38px; } }
    @media (max-width: 991px) {
      .game-card__title {
        font-size: 28px;
        line-height: 39px;
        margin-bottom: 20px; } }
  .game-card__icon {
    margin-bottom: 20px;
    max-width: 32px; }
  .game-card__description {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #414141;
    margin-bottom: 18px; }
    @media (max-width: 991px) {
      .game-card__description {
        font-size: 20px; } }
  .game-card__thumbnail-container {
    max-height: 300px;
    min-height: 300px;
    width: 100%;
    position: relative; }
    @media (max-width: 991px) {
      .game-card__thumbnail-container {
        max-height: 160px;
        min-height: 160px; } }
  .game-card__thumbnail {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    background-position: center;
    border-radius: 25px;
    overflow: hidden;
    z-index: 1; }
    .game-card__thumbnail--hover {
      opacity: 0;
      z-index: 2;
      transition: all 0.3s ease; }
  .game-card__copy-hover {
    width: 100%;
    position: absolute;
    z-index: 3;
    color: #FFFFFF;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    opacity: 0;
    font-size: 40px;
    line-height: 63px;
    transition: all 0.3s ease; }
    @media (max-width: 991px) {
      .game-card__copy-hover {
        font-size: 30px;
        line-height: 43px; } }
  .game-card__cta-container {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3; }
  .game-card__cta {
    position: absolute;
    width: max-content;
    bottom: -34px; }
    @media (max-width: 991px) {
      .game-card__cta {
        bottom: -85px; } }
    .game-card__cta svg {
      opacity: .6; }

.steps {
  padding: 10px 0 117px;
  background-color: #F8F8F8;
  background: linear-gradient(0deg, #F2F2F2 50%, #F8F8F8 50%); }
  @media (max-width: 991px) {
    .steps {
      padding: 20px 0 117px; } }
  @media (max-width: 767px) {
    .steps {
      background: linear-gradient(0deg, #F2F2F2 61%, #F8F8F8 39%); } }
  .steps__title {
    margin-bottom: 50px; }
    @media (max-width: 991px) {
      .steps__title {
        margin-bottom: 30px;
        text-align: center; } }
  .steps__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px 19px; }
    @media (max-width: 991px) {
      .steps__content {
        display: none; } }
  .steps__slider {
    display: none; }
    @media (max-width: 991px) {
      .steps__slider {
        display: block; } }

.step-card {
  padding: 70px 30px;
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 20px;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 25px;
  position: relative; }
  @media (max-width: 991px) {
    .step-card {
      grid-template-columns: 1fr;
      text-align: center;
      padding: 66px 33px 33px;
      margin: 32px 5px 0; } }
  .step-card__index {
    position: absolute;
    background-color: #F8F8F8;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid #F13764;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Nunito, "sans-serif";
    font-size: 35px;
    line-height: 47px;
    font-weight: 900;
    color: #F13764;
    top: -30px;
    left: 30px; }
    @media (max-width: 991px) {
      .step-card__index {
        left: 50%;
        transform: translateX(-50%); } }
  .step-card__icon-container {
    width: 100%;
    height: 120px;
    background: rgba(241, 55, 100, 0.1);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 991px) {
      .step-card__icon-container {
        width: 116px;
        height: 116px;
        margin: 0 auto; } }
  .step-card__description {
    font-size: 24px;
    line-height: 36px; }
    @media (max-width: 991px) {
      .step-card__description {
        font-size: 22px;
        line-height: 28.6px; } }

.footer {
  background-color: #595959;
  padding: 20px 0; }
  @media (max-width: 767px) {
    .footer {
      padding: 51px 0; } }
  .footer__container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  @media (max-width: 767px) {
    .footer__left-side {
      display: none; } }
  .footer__right-side {
    display: flex; }
    @media (max-width: 767px) {
      .footer__right-side {
        width: 100%;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        gap: 20px; } }
  .footer__copy {
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    color: #FFFFFF;
    mix-blend-mode: soft-light;
    margin-right: 20px; }
    @media (max-width: 767px) {
      .footer__copy {
        margin-right: initial; } }

.form {
  z-index: 10;
  position: absolute;
  top: 30px;
  right: 0;
  width: 100%;
  max-width: 540px;
  background-color: #FFFFFF;
  padding: 46px 30px;
  border-radius: 25px;
  box-shadow: 12px 12px #fab4b4;
  display: none;
  transform: initial;
  opacity: 0;
  transform: translatex(600px); }
  @media (max-width: 1079px) {
    .form {
      max-width: initial;
      margin-bottom: 50px;
      padding: 48px 22px; } }
  @media (max-width: 767px) {
    .form {
      margin-bottom: 100px; } }
  .form--just-ask {
    display: block; }
  .form--enter-active {
    opacity: 1;
    transition: all .5s;
    transition-delay: .5s;
    transition-timing-function: cubic-bezier(0, 1.26, 0.99, 0.97);
    transform: initial; }
  .form--exit-active {
    transform: translatex(600px);
    opacity: 0;
    transition: all .5s;
    display: none; }
  .form--exit {
    opacity: 0;
    transition: all .5s;
    display: none; }
  .form--enter-done {
    transform: initial;
    opacity: 1; }
  @media (max-width: 1079px) {
    .form {
      position: relative;
      top: initial; } }
  .form__input-container {
    padding-bottom: 15px; }
    .form__input-container.textarea-container {
      padding-bottom: 10px; }
    .form__input-container label {
      transition: all 0.3s ease;
      font-family: Nunito, "sans-serif";
      display: block;
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
      color: #696969;
      margin-bottom: 10px; }
    .form__input-container p {
      color: #F13764;
      font-size: 15px;
      padding: 5px 0; }
    .form__input-container input, .form__input-container textarea {
      transition: all 0.3s ease;
      font-family: Nunito, "sans-serif";
      font-size: 15px;
      width: 100%;
      border: 1px solid #E1E1E1;
      border-radius: 10px;
      padding: 20px 20px 20px 65px; }
      .form__input-container input:focus, .form__input-container textarea:focus {
        outline: none; }
    .form__input-container textarea {
      min-height: 118px;
      max-height: 200px;
      min-width: 100%;
      max-width: 100%; }
    .form__input-container.error label {
      color: #F13764; }
    .form__input-container.error input, .form__input-container.error textarea {
      border: 1px solid #F13764; }
  .form__icon {
    position: absolute;
    top: 50%;
    left: 20px;
    color: #E1E1E1;
    transform: translateY(-50%); }
    .form__icon--name {
      font-size: 28px; }
    .form__icon--email {
      font-size: 24px; }
    .form__icon--textarea {
      font-size: 24px;
      top: 19px;
      transform: initial; }
  .form__input:focus, .form__textarea:focus {
    border: 1.5px solid #B6B6B6; }
  .form__input-content {
    position: relative; }
  .form__disclaimer {
    font-size: 12px;
    line-height: 14px;
    color: #969696; }
    .form__disclaimer span {
      color: #F13764; }
  .form__submit-container {
    margin-top: 10px;
    display: flex;
    align-items: center; }
    @media (max-width: 1079px) {
      .form__submit-container {
        justify-content: center; } }
    @media (max-width: 767px) {
      .form__submit-container {
        margin-top: 20px; } }
  .form__submit {
    font-family: Nunito, "sans-serif";
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: 16px;
    line-height: 24px;
    color: #686868;
    font-weight: 900;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: initial;
    padding-right: initial; }
    .form__submit:focus {
      outline: none; }
    @media (max-width: 1079px) {
      .form__submit {
        margin-left: initial; } }
  .form__submit-icon {
    color: #BDBDBD;
    margin-left: 10px; }

@keyframes formMessageError {
  0% {
    transform: initial; }
  1% {
    opacity: 0; }
  30% {
    opacity: 100; }
  70% {
    opacity: 100; }
  99% {
    opacity: 0; }
  100% {
    transform: initial; } }

@keyframes formMessageSuccess {
  0% {
    transform: initial; }
  1% {
    opacity: 0; }
  30% {
    opacity: 100; }
  70% {
    opacity: 100; }
  99% {
    opacity: 0; }
  100% {
    transform: initial; } }

.form-message {
  padding: 20px;
  border-radius: 25px;
  background-color: #FFFFFF;
  border: 0.5px solid #E8E8E8;
  box-shadow: 5px 5px #EBEBEB;
  max-width: 312px;
  width: 100%;
  position: absolute;
  bottom: 110px;
  right: -70px;
  opacity: 0;
  transform: translateX(300px);
  transition: all 0.3s ease; }
  .form-message--success {
    animation: formMessageSuccess 5s; }
  .form-message--error {
    animation: formMessageError 5s; }
  .form-message:after {
    content: '';
    width: 33px;
    height: 33px;
    position: absolute;
    bottom: -17px;
    left: 130px;
    border-radius: 0px 0px 10px 0px;
    transform: matrix(0.71, 0.74, -0.68, 0.71, 0, 0);
    background-color: #FFFFFF;
    box-shadow: 6px 0px #ebebeb; }
  .form-message__container {
    margin-bottom: 15px;
    display: flex; }
  .form-message__title {
    font-family: Nunito, "sans-serif";
    font-size: 24px;
    line-height: 32px;
    color: #414141;
    font-weight: bold; }
  .form-message__icon {
    margin-right: 10px; }
  .form-message__copy {
    font-family: Nunito, "sans-serif";
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color: #525252; }

.modal-games {
  padding: 35px 80px;
  background: #FFFFFF;
  border-radius: 15px;
  position: relative;
  max-width: 66vh; }
  @media (max-width: 991px) {
    .modal-games {
      width: 100%;
      padding: 20px 70px; } }
  @media (max-width: 767px) {
    .modal-games {
      padding: 20px 30px; } }
  .modal-games--memory {
    max-width: max-content; }
    @media (max-width: 1079px) {
      .modal-games--memory {
        padding: 20px 60px 30px; } }
    @media (max-width: 767px) {
      .modal-games--memory {
        padding: 25px; } }
  .modal-games--virus-attack {
    width: 100%;
    height: 95%; }
    .modal-games--virus-attack iframe {
      width: 100%;
      border: none;
      height: 100%; }
  .modal-games--save-from-mosquito {
    max-width: 158vh;
    width: 100%;
    height: 92%; }
    @media (max-width: 1439px) {
      .modal-games--save-from-mosquito {
        height: 60vw; } }
    @media (max-width: 767px) {
      .modal-games--save-from-mosquito {
        height: 50vw; } }
    .modal-games--save-from-mosquito iframe {
      width: 100%;
      border: none;
      height: 100%; }

.mute {
  width: 49px;
  height: 49px;
  border-radius: 15px;
  background: transparent;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
  bottom: 27px;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  display: block; }
  @media (max-width: 991px) {
    .mute {
      left: 30px; } }

.puzzle {
  position: relative; }
  .puzzle__table-container {
    transition: all 0.3s ease; }
    .puzzle__table-container.active {
      opacity: 0; }
  .puzzle__table {
    border-radius: 2px;
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    gap: 1px;
    position: relative;
    max-width: 500px;
    margin: 0 auto 32px; }
    .puzzle__table:after {
      content: '';
      clear: both;
      display: block;
      height: 0px;
      visibility: hidden; }
  .puzzle__img-container {
    float: left;
    padding: 2px;
    cursor: pointer;
    left: 0px;
    top: 0px;
    position: relative;
    transition: 0.1s ease transform; }
    .puzzle__img-container:active {
      transform: scale(0.97); }
    .puzzle__img-container.blank {
      cursor: auto; }
    @media (max-width: 767px) {
      .puzzle__img-container {
        padding: 1px; } }
    .puzzle__img-container img {
      display: block;
      width: 100%; }
  .puzzle__buttons {
    display: flex;
    justify-content: center;
    gap: 38px; }
  .puzzle__timer-container {
    display: flex;
    justify-content: center;
    margin-bottom: 32px; }
  .puzzle__timer {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background-color: #FEEBF0;
    border-radius: 25px;
    gap: 13px; }
  .puzzle__timer-count {
    color: #F13764;
    font-weight: 900;
    font-size: 16px; }
  .puzzle__arrow {
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-bottom: 2px; }
    .puzzle__arrow--right {
      right: -50px;
      padding-left: 2px; }
    .puzzle__arrow--left {
      left: -50px;
      padding-right: 2px; }

.memory-game {
  width: max-content;
  margin: 0 auto; }
  .memory-game__table {
    display: grid;
    grid-template-columns: repeat(6, 95px);
    gap: 11px;
    margin-bottom: 37px;
    padding: 36px;
    background-color: #F4F4F4;
    transition: all 0.3s ease; }
    .memory-game__table.active {
      opacity: 0; }
    @media (max-width: 1079px) {
      .memory-game__table {
        grid-template-columns: repeat(6, 85px);
        padding: 29px; } }
    @media (max-width: 575px) {
      .memory-game__table {
        grid-template-columns: repeat(4, 60px);
        gap: 5px;
        padding: 12px;
        margin-bottom: 20px; } }
    @media (max-width: 320px) {
      .memory-game__table {
        grid-template-columns: repeat(4, 50px); } }
  .memory-game__card {
    height: 95px;
    user-select: none;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative; }
    @media (max-width: 1079px) {
      .memory-game__card {
        height: 85px; } }
    @media (max-width: 575px) {
      .memory-game__card {
        height: 60px; } }
    @media (max-width: 320px) {
      .memory-game__card {
        height: 50px; } }
    .memory-game__card div {
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      transition: 0.6s; }
    .memory-game__card.opened {
      transform: rotateY(180deg); }
    .memory-game__card.matched {
      transform: rotateY(180deg); }
      .memory-game__card.matched .memory-game__back {
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05) inset;
        animation: selected .8s 0s ease 1;
        animation-fill-mode: both;
        opacity: .2; }
  .memory-game__btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
  .memory-game__front {
    font-size: 50px;
    line-height: 120px;
    cursor: pointer;
    background-color: #D6D6D6;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease; }
    .memory-game__front:hover {
      background-color: #FFC4D2; }
      .memory-game__front:hover .memory-game__front-logo path {
        fill: #F13764; }
  .memory-game__front-logo {
    width: 55%; }
    .memory-game__front-logo path {
      transition: all 0.3s ease; }
  .memory-game__back {
    transform: rotateY(180deg);
    line-height: 110px; }
    .memory-game__back img {
      vertical-align: middle;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute; }

@keyframes selected {
  0% {
    opacity: .2; }
  30% {
    opacity: .5; }
  50% {
    opacity: .9; }
  70% {
    opacity: .2; }
  100% {
    opacity: .3; } }

.modal-games {
  padding: 35px 80px;
  background: #FFFFFF;
  border-radius: 15px;
  position: relative;
  max-width: 66vh; }
  @media (max-width: 991px) {
    .modal-games {
      width: 100%;
      padding: 20px 70px; } }
  @media (max-width: 767px) {
    .modal-games {
      padding: 20px 30px; } }
  .modal-games--memory {
    max-width: max-content; }
    @media (max-width: 1079px) {
      .modal-games--memory {
        padding: 20px 60px 30px; } }
    @media (max-width: 767px) {
      .modal-games--memory {
        padding: 25px; } }
  .modal-games--virus-attack {
    width: 100%;
    height: 95%; }
    .modal-games--virus-attack iframe {
      width: 100%;
      border: none;
      height: 100%; }
  .modal-games--save-from-mosquito {
    max-width: 158vh;
    width: 100%;
    height: 92%; }
    @media (max-width: 1439px) {
      .modal-games--save-from-mosquito {
        height: 60vw; } }
    @media (max-width: 767px) {
      .modal-games--save-from-mosquito {
        height: 50vw; } }
    .modal-games--save-from-mosquito iframe {
      width: 100%;
      border: none;
      height: 100%; }

.mute {
  width: 49px;
  height: 49px;
  border-radius: 15px;
  background: transparent;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
  bottom: 27px;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  display: block; }
  @media (max-width: 991px) {
    .mute {
      left: 30px; } }

.puzzle {
  position: relative; }
  .puzzle__table-container {
    transition: all 0.3s ease; }
    .puzzle__table-container.active {
      opacity: 0; }
  .puzzle__table {
    border-radius: 2px;
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    gap: 1px;
    position: relative;
    max-width: 500px;
    margin: 0 auto 32px; }
    .puzzle__table:after {
      content: '';
      clear: both;
      display: block;
      height: 0px;
      visibility: hidden; }
  .puzzle__img-container {
    float: left;
    padding: 2px;
    cursor: pointer;
    left: 0px;
    top: 0px;
    position: relative;
    transition: 0.1s ease transform; }
    .puzzle__img-container:active {
      transform: scale(0.97); }
    .puzzle__img-container.blank {
      cursor: auto; }
    @media (max-width: 767px) {
      .puzzle__img-container {
        padding: 1px; } }
    .puzzle__img-container img {
      display: block;
      width: 100%; }
  .puzzle__buttons {
    display: flex;
    justify-content: center;
    gap: 38px; }
  .puzzle__timer-container {
    display: flex;
    justify-content: center;
    margin-bottom: 32px; }
  .puzzle__timer {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background-color: #FEEBF0;
    border-radius: 25px;
    gap: 13px; }
  .puzzle__timer-count {
    color: #F13764;
    font-weight: 900;
    font-size: 16px; }
  .puzzle__arrow {
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-bottom: 2px; }
    .puzzle__arrow--right {
      right: -50px;
      padding-left: 2px; }
    .puzzle__arrow--left {
      left: -50px;
      padding-right: 2px; }

.modal-games {
  padding: 35px 80px;
  background: #FFFFFF;
  border-radius: 15px;
  position: relative;
  max-width: 66vh; }
  @media (max-width: 991px) {
    .modal-games {
      width: 100%;
      padding: 20px 70px; } }
  @media (max-width: 767px) {
    .modal-games {
      padding: 20px 30px; } }
  .modal-games--memory {
    max-width: max-content; }
    @media (max-width: 1079px) {
      .modal-games--memory {
        padding: 20px 60px 30px; } }
    @media (max-width: 767px) {
      .modal-games--memory {
        padding: 25px; } }
  .modal-games--virus-attack {
    width: 100%;
    height: 95%; }
    .modal-games--virus-attack iframe {
      width: 100%;
      border: none;
      height: 100%; }
  .modal-games--save-from-mosquito {
    max-width: 158vh;
    width: 100%;
    height: 92%; }
    @media (max-width: 1439px) {
      .modal-games--save-from-mosquito {
        height: 60vw; } }
    @media (max-width: 767px) {
      .modal-games--save-from-mosquito {
        height: 50vw; } }
    .modal-games--save-from-mosquito iframe {
      width: 100%;
      border: none;
      height: 100%; }

.memory-game {
  width: max-content;
  margin: 0 auto; }
  .memory-game__table {
    display: grid;
    grid-template-columns: repeat(6, 95px);
    gap: 11px;
    margin-bottom: 37px;
    padding: 36px;
    background-color: #F4F4F4;
    transition: all 0.3s ease; }
    .memory-game__table.active {
      opacity: 0; }
    @media (max-width: 1079px) {
      .memory-game__table {
        grid-template-columns: repeat(6, 85px);
        padding: 29px; } }
    @media (max-width: 575px) {
      .memory-game__table {
        grid-template-columns: repeat(4, 60px);
        gap: 5px;
        padding: 12px;
        margin-bottom: 20px; } }
    @media (max-width: 320px) {
      .memory-game__table {
        grid-template-columns: repeat(4, 50px); } }
  .memory-game__card {
    height: 95px;
    user-select: none;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative; }
    @media (max-width: 1079px) {
      .memory-game__card {
        height: 85px; } }
    @media (max-width: 575px) {
      .memory-game__card {
        height: 60px; } }
    @media (max-width: 320px) {
      .memory-game__card {
        height: 50px; } }
    .memory-game__card div {
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      transition: 0.6s; }
    .memory-game__card.opened {
      transform: rotateY(180deg); }
    .memory-game__card.matched {
      transform: rotateY(180deg); }
      .memory-game__card.matched .memory-game__back {
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05) inset;
        animation: selected .8s 0s ease 1;
        animation-fill-mode: both;
        opacity: .2; }
  .memory-game__btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
  .memory-game__front {
    font-size: 50px;
    line-height: 120px;
    cursor: pointer;
    background-color: #D6D6D6;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease; }
    .memory-game__front:hover {
      background-color: #FFC4D2; }
      .memory-game__front:hover .memory-game__front-logo path {
        fill: #F13764; }
  .memory-game__front-logo {
    width: 55%; }
    .memory-game__front-logo path {
      transition: all 0.3s ease; }
  .memory-game__back {
    transform: rotateY(180deg);
    line-height: 110px; }
    .memory-game__back img {
      vertical-align: middle;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute; }

@keyframes selected {
  0% {
    opacity: .2; }
  30% {
    opacity: .5; }
  50% {
    opacity: .9; }
  70% {
    opacity: .2; }
  100% {
    opacity: .3; } }

.winner {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  opacity: 0;
  transition: all 0.3s ease; }
  .winner.active {
    opacity: 1; }
  .winner__img-container {
    max-width: 220px;
    margin: 0 auto 12px;
    position: relative; }
    @media (max-width: 767px) {
      .winner__img-container {
        max-width: 190px; } }
  .winner__img {
    position: relative;
    z-index: 1;
    width: 100%; }
  .winner__title {
    font-size: 40px; }
    @media (max-width: 767px) {
      .winner__title {
        font-size: 30px; } }
  .winner__circle {
    position: absolute;
    border-radius: 50%;
    animation: floatIcons 5s ease-in-out infinite; }
    .winner__circle--big {
      background-color: #FFE6EC;
      width: 104px;
      height: 104px;
      right: -11px;
      top: 35px;
      z-index: 0;
      animation-delay: 1s; }
    .winner__circle--small {
      border: 2px solid #FF90AB;
      width: 37px;
      height: 37px;
      left: 0;
      top: 50%;
      z-index: 2; }
  .winner__confetti {
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    width: 100vw !important;
    height: 100vh !important; }

@keyframes appearWinner {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes expand {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(-100px, -60px);
    @media (max-width: 1079px) {
      transform: translateY(-20px); } } }

@keyframes expandMobile {
  from {
    transform: translate(0, 0); }
  to {
    transform: translateY(-20px); } }

.hero .social-media {
  position: absolute;
  bottom: 37px;
  left: 90px; }
  @media (max-width: 991px) {
    .hero .social-media {
      display: none; } }

.hero__info {
  position: relative;
  padding: 94px 0 0 102px;
  max-width: 765px;
  color: #FFFFFF;
  z-index: 3; }
  @media (min-width: 2000px) {
    .hero__info {
      padding: 134px 0 0 0px;
      max-width: initial; } }
  @media (max-width: 1199px) {
    .hero__info {
      padding: 94px 0 0; } }
  @media (max-width: 991px) {
    .hero__info {
      text-align: center;
      padding: initial;
      max-width: 600px; } }
  .hero__info--enter {
    transform: translateX(400px);
    opacity: 0; }
  .hero__info--enter-active {
    transform: translateX(0%);
    opacity: 1;
    transition: all 1s; }
  .hero__info--exit {
    position: absolute;
    top: 0;
    left: 200px;
    transform: translateX(0%); }
  .hero__info--exit-active {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-200px);
    opacity: 0;
    transition: all .5s; }
  .hero__info.expanded {
    max-width: none;
    animation-name: expand;
    animation-duration: .5s;
    animation-fill-mode: forwards; }
    @media (max-width: 1079px) {
      .hero__info.expanded {
        animation-name: expandMobile; } }

.hero__ghost-title {
  position: absolute;
  top: -9px;
  left: -67px;
  width: max-content;
  font-family: Oi, "cursive";
  font-size: 130px;
  color: white;
  opacity: .1; }
  @media (min-width: 2500px) {
    .hero__ghost-title {
      font-size: 260px; } }
  @media (min-width: 2000px) {
    .hero__ghost-title {
      font-size: 200px; } }
  @media (max-width: 991px) {
    .hero__ghost-title {
      left: 50%;
      transform: translateX(-50%); } }
  @media (max-width: 800px) {
    .hero__ghost-title {
      display: none; } }
  .hero__ghost-title--enter {
    left: 800px; }
  .hero__ghost-title--enter-active {
    left: -67px;
    transition: all 0.1s; }
  .hero__ghost-title--exit-active {
    transform: translateX(-200px);
    opacity: 0;
    transition: all .5s; }

@media (max-width: 991px) {
  .hero__title {
    margin-bottom: 15px; } }

@media (min-width: 2500px) {
  .hero__title {
    font-size: 150px; } }

.hero__copy {
  margin-bottom: 50px;
  max-width: 490px; }
  @media (min-width: 2500px) {
    .hero__copy {
      font-size: 40px;
      max-width: 50%; } }
  @media (max-width: 991px) {
    .hero__copy {
      margin-bottom: 50px;
      max-width: initial; } }
  @media (max-width: 991px) {
    .hero__copy {
      max-width: 490px;
      margin: 0 auto 50px; } }

.hero__button {
  font-size: 16px; }
  @media (min-width: 2500px) {
    .hero__button {
      font-size: 40px; } }
  @media (min-width: 2000px) {
    .hero__button {
      font-size: 32px; } }
  @media (max-width: 991px) {
    .hero__button {
      width: 100%;
      justify-content: center; } }

.hero__form-container {
  width: 100%; }

@keyframes morph {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes morphBackwards {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 0; } }

@keyframes floatIcons {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(10px); }
  100% {
    transform: translateY(0px); } }

.character {
  position: absolute;
  right: 50px;
  bottom: 0;
  z-index: 2;
  transition: all .5s ease; }
  @media (min-width: 2500px) {
    .character {
      right: -156px; } }
  @media (max-width: 1079px) {
    .character {
      right: 0; } }
  @media (max-width: 991px) {
    .character {
      position: relative;
      min-height: 680px;
      display: flex;
      align-items: flex-end;
      width: 677px;
      height: 560px; } }
  @media (max-width: 767px) {
    .character {
      width: 520px;
      height: 433px;
      min-height: 450px; } }
  .character__circle {
    border-radius: 50%;
    position: absolute;
    transition: opacity .5s ease; }
    .character__circle--primary {
      width: 265px;
      height: 265px;
      bottom: 30%;
      left: 0; }
      @media (min-width: 2500px) {
        .character__circle--primary {
          width: 600px;
          height: 600px; } }
      @media (max-width: 991px) {
        .character__circle--primary {
          width: 223px;
          height: 223px;
          bottom: 43%; } }
    .character__circle--secondary {
      width: 315px;
      height: 315px;
      right: 0;
      bottom: 0;
      transform: rotate(165deg); }
      @media (min-width: 2500px) {
        .character__circle--secondary {
          width: 630px;
          height: 630px; } }
      @media (max-width: 991px) {
        .character__circle--secondary {
          width: 265px;
          height: 265px; } }
    .character__circle--tertiary {
      width: 75px;
      height: 75px;
      right: 23%;
      bottom: 33%; }
      @media (min-width: 2500px) {
        .character__circle--tertiary {
          width: 150px;
          height: 150px; } }
      @media (max-width: 991px) {
        .character__circle--tertiary {
          width: 63px;
          height: 63px;
          bottom: 37%; } }
    .character__circle--comics.character__circle--primary {
      background: linear-gradient(68.51deg, rgba(97, 97, 97, 0) 11.08%, #525252 65.89%); }
    .character__circle--comics.character__circle--secondary {
      background: linear-gradient(109.64deg, rgba(100, 100, 100, 0) 17.89%, #484848 76.08%); }
    .character__circle--comics.character__circle--tertiary {
      border: 3px solid #696969; }
    .character__circle--voices.character__circle--primary {
      background: linear-gradient(193.38deg, rgba(93, 81, 184, 0) 22.98%, rgba(158, 122, 234, 0.231) 90.39%);
      width: 155px;
      height: 155px;
      bottom: 35%;
      left: 11%; }
      @media (min-width: 2500px) {
        .character__circle--voices.character__circle--primary {
          width: 505px;
          height: 505px;
          bottom: 24%;
          left: 5%; } }
      @media (max-width: 991px) {
        .character__circle--voices.character__circle--primary {
          width: 205px;
          height: 205px;
          bottom: 11%;
          left: 17%; } }
    .character__circle--voices.character__circle--secondary {
      background: linear-gradient(193.38deg, rgba(93, 81, 184, 0) 22.98%, rgba(158, 122, 234, 0.231) 90.39%);
      opacity: 0; }
    .character__circle--voices.character__circle--tertiary {
      border: 3px solid #855CDB;
      top: 16%;
      left: 24%; }
      @media (max-width: 991px) {
        .character__circle--voices.character__circle--tertiary {
          top: 9%;
          left: 18%; } }
    .character__circle--games.character__circle--primary {
      background: linear-gradient(180deg, rgba(213, 42, 84, 0) 0%, #D32650 100%);
      width: 165px;
      height: 165px;
      bottom: 18%;
      left: 9%; }
      @media (min-width: 2500px) {
        .character__circle--games.character__circle--primary {
          width: 365px;
          height: 365px; } }
    .character__circle--games.character__circle--secondary {
      background: linear-gradient(180deg, rgba(209, 41, 82, 0) 0%, #DC2854 100%);
      width: 275px;
      height: 275px;
      right: 10%;
      top: 7%; }
      @media (min-width: 2500px) {
        .character__circle--games.character__circle--secondary {
          width: 610px;
          height: 610px; } }
      @media (max-width: 991px) {
        .character__circle--games.character__circle--secondary {
          width: 225px;
          height: 225px;
          right: 4%;
          top: 2%; } }
    .character__circle--games.character__circle--tertiary {
      border: 3px solid #FD6E90;
      top: 22%;
      left: 23%; }
      @media (max-width: 991px) {
        .character__circle--games.character__circle--tertiary {
          top: 18%;
          left: 19%; } }
    .character__circle--teaching-portal.character__circle--primary {
      background: linear-gradient(134.84deg, rgba(64, 183, 97, 0.41) 25.19%, #47B465 79.73%);
      width: 340px;
      height: 340px;
      bottom: 21%;
      left: 0; }
      @media (min-width: 2500px) {
        .character__circle--teaching-portal.character__circle--primary {
          width: 620px;
          height: 620px; } }
      @media (max-width: 991px) {
        .character__circle--teaching-portal.character__circle--primary {
          width: 255px;
          height: 255px; } }
    .character__circle--teaching-portal.character__circle--secondary {
      background: linear-gradient(55.26deg, rgba(58, 171, 89, 0) 14.4%, #4BB769 71.51%);
      transform: initial;
      top: 29%;
      right: 10%;
      width: 205px;
      height: 205px; }
      @media (min-width: 2500px) {
        .character__circle--teaching-portal.character__circle--secondary {
          width: 405px;
          height: 405px; } }
    .character__circle--teaching-portal.character__circle--tertiary {
      border: 3px solid #60D380;
      top: 26%;
      left: 25%; }
      @media (max-width: 991px) {
        .character__circle--teaching-portal.character__circle--tertiary {
          top: 19%; } }
    .character__circle--just-in.character__circle--primary {
      background: linear-gradient(119deg, rgba(58, 71, 191, 0) 23.64%, #303EB6 84.37%);
      width: 177px;
      height: 177px;
      bottom: 24%;
      left: 10%; }
      @media (min-width: 2500px) {
        .character__circle--just-in.character__circle--primary {
          width: 477px;
          height: 477px; } }
    .character__circle--just-in.character__circle--secondary {
      background: linear-gradient(87.67deg, #3F4CC1 0.7%, #3743BB 75.37%);
      bottom: 25%; }
    .character__circle--just-in.character__circle--tertiary {
      border: 3px solid #4E5ACD;
      left: 20%;
      top: 38%; }
      @media (max-width: 991px) {
        .character__circle--just-in.character__circle--tertiary {
          top: 25%; } }
    .character__circle--pub-tv.character__circle--primary {
      background: linear-gradient(142.97deg, rgba(217, 117, 61, 0) 25.86%, #E47334 67.79%);
      width: 195px;
      height: 195px;
      bottom: 3%; }
    .character__circle--pub-tv.character__circle--secondary {
      background: linear-gradient(165.54deg, rgba(231, 109, 41, 0) 8.63%, rgba(209, 96, 33, 0.54) 57.74%);
      width: 195px;
      height: 195px;
      right: 13%;
      bottom: 13%; }
    .character__circle--pub-tv.character__circle--tertiary {
      border: 3px solid #FFA674;
      left: 12%;
      bottom: 50%;
      z-index: 3; }
      @media (max-width: 991px) {
        .character__circle--pub-tv.character__circle--tertiary {
          left: 6%;
          bottom: 45%; } }
  .character__main-image {
    position: relative;
    width: 677px;
    bottom: 0;
    right: 0;
    animation: morph 0.8s both;
    vertical-align: bottom; }
    @media (min-width: 2500px) {
      .character__main-image {
        width: 1400px; } }
    @media (max-width: 1079px) {
      .character__main-image {
        width: 68vh; } }
    @media (max-width: 991px) {
      .character__main-image {
        width: 677px;
        position: relative;
        right: initial;
        bottom: initial;
        object-fit: cover;
        height: 560px;
        object-position: bottom; } }
    @media (max-width: 767px) {
      .character__main-image {
        width: 520px;
        height: 433px; } }
    .character__main-image--enter-done {
      position: relative; }
    .character__main-image--exit-active {
      position: absolute; }
    .character__main-image--just-ask {
      transform: translate(0, 0);
      bottom: initial;
      top: 120px;
      max-width: 375px; }
      @media (min-width: 2000px) {
        .character__main-image--just-ask {
          top: 244px;
          max-width: 550px; } }
      @media (max-width: 1439px) {
        .character__main-image--just-ask {
          transform: translate(0, 0); } }
      @media (max-width: 1079px) {
        .character__main-image--just-ask {
          display: none; } }
      .character__main-image--just-ask.character__main-image--exit {
        animation: morphBackwards 1s both; }
      .character__main-image--just-ask.character__main-image--exit-active {
        transform: translate(0, 0);
        animation: morph 1s both; }
        @media (max-width: 1439px) {
          .character__main-image--just-ask.character__main-image--exit-active {
            transform: translate(0, 0); } }
      .character__main-image--just-ask.character__main-image--enter-active {
        transform: translate(-595px, 0); }
      .character__main-image--just-ask.character__main-image--enter-done {
        position: absolute;
        opacity: 1;
        animation: morph 1s both;
        transform: translate(-595px, 0); }
    .character__main-image--pub-tv {
      width: 750px; }
      @media (min-width: 2000px) {
        .character__main-image--pub-tv {
          width: 1050px; } }
      @media (min-width: 2500px) {
        .character__main-image--pub-tv {
          width: 1500px; } }
      @media (max-width: 1025px) and (min-height: 1300px) {
        .character__main-image--pub-tv {
          width: 100%; } }
      @media (max-width: 991px) {
        .character__main-image--pub-tv {
          width: 100%; } }
      @media (max-width: 767px) {
        .character__main-image--pub-tv {
          width: 520px;
          right: 0; } }
  .character__back-icons1, .character__back-icons2, .character__back-icons3 {
    position: absolute;
    width: auto;
    animation: floatIcons 5s ease-in-out infinite; }
    .character__back-icons1 div, .character__back-icons2 div, .character__back-icons3 div {
      animation: morph 0.8s both; }
  .character__back-icons1 {
    width: 150px;
    height: 150px; }
    @media (min-width: 2500px) {
      .character__back-icons1 {
        width: 250px;
        height: 250px; } }
    .character__back-icons1--voices {
      top: 10%;
      left: 57%; }
      @media (max-width: 767px) {
        .character__back-icons1--voices {
          top: -10%;
          left: 50%; } }
    .character__back-icons1--comics {
      top: 17%;
      left: 61%; }
      @media (max-width: 1079px) {
        .character__back-icons1--comics {
          top: 26%;
          left: 68%; } }
      @media (max-width: 767px) {
        .character__back-icons1--comics {
          top: 0%;
          left: 65%; } }
    .character__back-icons1--games {
      top: 17%;
      left: 61%; }
    .character__back-icons1--teaching-portal {
      top: 19%;
      left: 63%; }
      @media (max-width: 767px) {
        .character__back-icons1--teaching-portal {
          top: 0%;
          left: 63%; } }
    .character__back-icons1--pub-tv {
      top: 6%;
      right: 18%; }
      @media (min-width: 2500px) {
        .character__back-icons1--pub-tv {
          right: 20%; } }
      @media (max-width: 991px) {
        .character__back-icons1--pub-tv {
          top: 14%;
          right: 15%; } }
      @media (max-width: 767px) {
        .character__back-icons1--pub-tv {
          top: 2%;
          right: 16%;
          width: 100px;
          height: 100px; } }
  .character__back-icons2 {
    transform: translateY(50%);
    width: 120px;
    height: 140px; }
    @media (min-width: 2500px) {
      .character__back-icons2 {
        width: 220px;
        height: 240px; } }
    .character__back-icons2--comics {
      top: 59%;
      left: 15%; }
      @media (max-width: 1079px) {
        .character__back-icons2--comics {
          top: 64%;
          left: 22%; } }
      @media (max-width: 767px) {
        .character__back-icons2--comics {
          top: 50%;
          left: 12%; } }
    .character__back-icons2--voices {
      top: 45%;
      left: 4%; }
      @media (min-width: 2500px) {
        .character__back-icons2--voices {
          left: 6%; } }
      @media (max-width: 767px) {
        .character__back-icons2--voices {
          top: 28%;
          left: 0%; } }
    .character__back-icons2--games {
      top: 60%;
      left: 13%; }
    .character__back-icons2--teaching-portal {
      top: 59%;
      left: 30%; }
      @media (max-width: 1079px) {
        .character__back-icons2--teaching-portal {
          top: 60%;
          left: 33%; } }
      @media (max-width: 767px) {
        .character__back-icons2--teaching-portal {
          top: 48%;
          left: 25%; } }
    .character__back-icons2--pub-tv {
      top: 9%;
      left: 47%; }
      @media (min-width: 2500px) {
        .character__back-icons2--pub-tv {
          top: 10%;
          left: 48%; } }
      @media (max-width: 991px) {
        .character__back-icons2--pub-tv {
          top: 19%; } }
      @media (max-width: 767px) {
        .character__back-icons2--pub-tv {
          top: 6%;
          left: 45%;
          width: 100px;
          height: 100px; } }
  .character__back-icons3 {
    transform: translateY(50%);
    width: 150px;
    height: 150px; }
    @media (min-width: 2500px) {
      .character__back-icons3 {
        width: 250px;
        height: 250px; } }
    .character__back-icons3--voices {
      top: 43%;
      right: 2%; }
      @media (min-width: 2500px) {
        .character__back-icons3--voices {
          top: 45%;
          right: 6%; } }
      @media (max-width: 767px) {
        .character__back-icons3--voices {
          top: 30%;
          right: -5%; } }
    .character__back-icons3--pub-tv {
      width: 173px;
      height: 40px;
      bottom: 19%;
      left: 5%; }
      @media (min-width: 2500px) {
        .character__back-icons3--pub-tv {
          width: 346px;
          height: 80px;
          left: 0%; } }
      @media (max-width: 1079px) {
        .character__back-icons3--pub-tv {
          left: 0; } }

.section-header {
  display: grid;
  gap: 17px 0;
  margin-bottom: 50px; }
  @media (max-width: 767px) {
    .section-header {
      text-align: center; } }
  .section-header__description {
    max-width: 450px; }

@keyframes animationCircle {
  0% {
    opacity: 0.9;
    transform: initial; }
  70% {
    transform: scale(2.9); }
  100% {
    opacity: 0;
    transform: scale(2.9); } }

@-webkit-keyframes animationCircle {
  0% {
    opacity: 0.9;
    -webkit-transform: initial;
    transform: initial; }
  70% {
    -webkit-transform: scale(2.9);
    transform: scale(2.9); }
  100% {
    opacity: 0;
    -webkit-transform: scale(2.9); } }

.donate-button {
  position: relative;
  z-index: 10;
  border-radius: 10px; }
  .donate-button:hover {
    mix-blend-mode: initial; }
  .donate-button__link {
    display: flex;
    align-items: center;
    text-decoration: initial;
    position: relative; }
  .donate-button__img {
    margin-right: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
  .donate-button__circle {
    animation: animationCircle 2s infinite;
    width: 26px;
    height: 26px;
    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    position: absolute; }
    .donate-button__circle--two {
      width: 22px;
      height: 22px;
      animation-delay: .2s; }
    .donate-button__circle--three {
      width: 16px;
      height: 16px;
      animation-delay: .4s; }
  .donate-button__copy {
    color: #FFFFFF;
    line-height: 17px;
    font-size: 20px;
    font-family: Nunito, "sans-serif";
    font-weight: 500; }

@keyframes bell {
  0% {
    transform: rotate(0); }
  20% {
    transform: rotate(30deg); }
  40% {
    transform: rotate(-30deg); }
  60% {
    transform: rotate(30deg); }
  80% {
    transform: rotate(-30deg); }
  100% {
    transform: rotate(0); } }

.alert-bar {
  background: linear-gradient(90deg, #F7D459 50%, #FFE074 50%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  z-index: 100;
  transition: all 0.3s ease;
  transform: translateY(-100px); }
  .alert-bar.active {
    opacity: 1;
    transform: initial; }
  .alert-bar__container {
    display: flex;
    justify-content: space-between; }
  .alert-bar__bell-container {
    padding: 0 20px;
    max-width: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F7D459; }
    @media (max-width: 767px) {
      .alert-bar__bell-container {
        padding: 0 11px; } }
  .alert-bar__bell {
    width: 100%;
    max-width: 42px;
    animation: bell 2s infinite;
    transform-origin: top; }
    @media (max-width: 767px) {
      .alert-bar__bell {
        max-width: 24px; } }
  .alert-bar__text-container {
    background-color: #FFE074;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0 18px 16px;
    width: -webkit-fill-available; }
  .alert-bar__copy, .alert-bar__copy a {
    font-size: 13px;
    line-height: 15.25px;
    color: #414141;
    letter-spacing: -0.02em;
    font-family: 'Work Sans';
    font-weight: 400; }
    @media (max-width: 767px) {
      .alert-bar__copy, .alert-bar__copy a {
        font-size: 12px; } }
  .alert-bar__close {
    transition: all 0.3s ease;
    cursor: pointer; }
    .alert-bar__close:hover {
      transform: scale(1.1); }

.comics__left {
  background-color: #4F4F4F; }

.comics__right {
  background-image: linear-gradient(180deg, #686868 32%, #545454 100%); }

.comics .main-content {
  position: absolute;
  top: 200px;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: white;
  height: 700px;
  width: 100%; }

.comics .navbar.active {
  background-color: #4F4F4F; }

.comics .navbar__item:last-of-type .navbar__link {
  background-color: #5CD67E; }
  .comics .navbar__item:last-of-type .navbar__link:hover {
    background-color: #8FEFAA; }

.comics .button {
  box-shadow: 15px 15px 25px rgba(16, 69, 31, 0.15);
  color: #4F4F4F; }
  .comics .button__icon {
    color: #686868; }

.comics .main-page__tab:after {
  border-bottom: 20px solid #4F4F4F; }

.games__left {
  background-color: #D42A53; }

.games__right {
  background-image: linear-gradient(180deg, #F13764 32%, #E12B57 100%); }

.games .hero .button {
  box-shadow: 15px 15px 25px rgba(82, 15, 31, 0.15);
  color: #D42A53; }
  .games .hero .button__icon {
    color: #F13764; }

.games .main-page__tab:after {
  border-bottom: 20px solid #D42A53; }

.games .navbar.active {
  background-color: #D42A53; }

.games .navbar__item:last-of-type .navbar__link {
  background-color: #5042BA; }
  .games .navbar__item:last-of-type .navbar__link:hover {
    background-color: #511C97; }

.intro-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9;
  display: block; }
  .intro-video.fade {
    display: none; }
  @media (max-width: 767px) {
    .intro-video {
      height: initial;
      transform: translateY(-50%);
      top: 50%; } }
  .intro-video__button {
    transition: all .8s ease;
    font-size: 24px;
    line-height: 36px;
    padding: 23px 27px;
    position: absolute;
    bottom: 100px;
    right: 50%;
    transform: translateX(50%);
    z-index: 10; }
    .intro-video__button svg {
      margin-left: 14px;
      width: 18px; }
    .intro-video__button.fade {
      opacity: 0; }
    @media (min-width: 2000px) {
      .intro-video__button {
        padding: 30px 35px;
        font-size: 32px; } }
    @media (max-width: 1439px) {
      .intro-video__button {
        bottom: 150px; } }
    @media (max-width: 1079px) {
      .intro-video__button {
        bottom: 100px; } }
    @media (max-width: 767px) {
      .intro-video__button {
        bottom: 45px;
        padding: 18px 20px;
        font-size: 20px;
        line-height: 30px; } }
    @media (max-width: 320px) {
      .intro-video__button {
        padding: 15px 17px;
        font-size: 18px; } }

.intro-video-container {
  background-color: #FFFFFF;
  height: 100vh;
  width: 100vw; }

.video-exit-active {
  opacity: 0;
  transition: all 1s; }

.teaching-portal__left {
  background-color: #42B462; }

.teaching-portal__right {
  background-image: linear-gradient(180deg, #4DC36E 32%, #42B863 100%); }

.teaching-portal .hero .button {
  box-shadow: 15px 15px 25px rgba(16, 69, 31, 0.15);
  color: #42B462; }
  .teaching-portal .hero .button__icon {
    color: #4DC36E; }

.teaching-portal .main-page__tab:after {
  border-bottom: 20px solid #42B462; }

.teaching-portal .navbar.active {
  background-color: #42B462; }

.teaching-portal .navbar__item:last-of-type .navbar__link {
  background-color: #5042BA; }
  .teaching-portal .navbar__item:last-of-type .navbar__link:hover {
    background-color: #511C97; }

.teaching-portal .videos-grid {
  background-color: #F2F2F2;
  padding: 87px 0px 50px; }

.just-in__left {
  background-color: #2C39AF; }

.just-in__right {
  background-image: linear-gradient(180deg, #4854C8 32%, #3845BC 100%); }

.just-in .button {
  box-shadow: 15px 15px 25px rgba(16, 69, 31, 0.15);
  color: #2C39AF; }
  .just-in .button__icon {
    color: #4854C8; }

.just-in .main-page__tab:after {
  border-bottom: 20px solid #2C39AF; }

.just-in .character__back-icons1 {
  right: 420px;
  bottom: -57px;
  margin-bottom: 151px; }
  @media (min-width: 2000px) {
    .just-in .character__back-icons1 {
      right: 430px; } }
  @media (min-width: 2500px) {
    .just-in .character__back-icons1 {
      right: 862px;
      bottom: 47px; } }
  .just-in .character__back-icons1--enter {
    right: -1000px;
    opacity: 0; }
  .just-in .character__back-icons1--enter-active {
    right: 420px;
    opacity: 1;
    transition: all .5s; }
  .just-in .character__back-icons1--enter-done {
    right: 420px; }
    @media (min-width: 2000px) {
      .just-in .character__back-icons1--enter-done {
        right: 430px; } }
    @media (min-width: 2500px) {
      .just-in .character__back-icons1--enter-done {
        right: 704px;
        bottom: 47px; } }
  .just-in .character__back-icons1--exit-active {
    right: 300px;
    opacity: 0;
    transition: all .5s; }
  @media (max-width: 1079px) {
    .just-in .character__back-icons1 {
      right: 583px;
      bottom: -21px; }
      .just-in .character__back-icons1--enter {
        opacity: 0;
        right: -200px;
        bottom: -21px;
        margin-bottom: 151px; }
      .just-in .character__back-icons1--enter-active {
        right: 583px;
        opacity: 1;
        transition: all .5s; } }
  @media (max-width: 991px) {
    .just-in .character__back-icons1 {
      right: 421px;
      bottom: -64px; }
      .just-in .character__back-icons1--enter {
        opacity: 0;
        right: -200px;
        bottom: -54px;
        margin-bottom: 151px; }
      .just-in .character__back-icons1--enter-active {
        right: 356px;
        opacity: 1;
        transition: all .5s; } }
  @media (max-width: 767px) {
    .just-in .character__back-icons1 {
      right: 321px;
      bottom: -99px; }
      .just-in .character__back-icons1--enter {
        opacity: 0;
        right: -200px;
        bottom: -54px;
        margin-bottom: 151px; }
      .just-in .character__back-icons1--enter-active {
        right: 321px;
        opacity: 1;
        transition: all .5s; } }

.just-in .navbar.active {
  background-color: #2C39AF; }

.just-in .navbar__item:last-of-type .navbar__link {
  background-color: #5CD67E; }
  .just-in .navbar__item:last-of-type .navbar__link:hover {
    background-color: #8FEFAA; }

.pub-tv__left {
  background-color: #EC7532; }

.pub-tv__right {
  background-image: linear-gradient(180deg, #FC8B4C 32%, #F08043 100%); }

.pub-tv .button {
  box-shadow: 15px 15px 25px rgba(112, 61, 21, 0.15);
  color: #EC7532; }
  .pub-tv .button__icon {
    color: #FC8B4C; }

.pub-tv .main-page__tab:after {
  border-bottom: 20px solid #EC7532; }

.pub-tv .navbar.active {
  background-color: #EC7532; }

.pub-tv .navbar__item:last-of-type .navbar__link {
  background-color: #5042BA; }
  .pub-tv .navbar__item:last-of-type .navbar__link:hover {
    background-color: #511C97; }

.voices__left {
  background-color: #511C97; }

.voices__right {
  background-image: linear-gradient(180deg, #7A50D1 32%, #5F28A5 100%); }

.voices .button {
  box-shadow: 15px 15px 25px rgba(29, 22, 87, 0.15);
  color: #511C97; }
  .voices .button__icon {
    color: #7A50D1; }

.voices .main-page__tab:after {
  border-bottom: 20px solid #511C97; }

.voices .navbar.active {
  background-color: #511C97; }

.voices .navbar__item:last-of-type {
  background-color: #F13764; }
  .voices .navbar__item:last-of-type:hover {
    background-color: #FF90AB; }

@media (max-width: 1079px) {
  .just-ask {
    height: initial; } }

.just-ask__left {
  background-color: #DB7B7B; }

.just-ask__right {
  background-color: #E99494; }

.just-ask .navbar.active {
  background-color: #DB7B7B; }

@media (max-width: 1079px) {
  .just-ask .main-page {
    position: relative; } }

.just-ask .main-page__content {
  display: none; }
  @media (max-width: 1079px) {
    .just-ask .main-page__content {
      position: relative; } }

@media screen and (min-width: 1020px) and (max-width: 1025px) and (min-height: 1360px) and (max-height: 1370px) {
  .just-ask .hero__form-container {
    margin-bottom: 340px; } }

@media (max-width: 1079px) {
  .just-ask .hero {
    justify-content: initial;
    padding-bottom: 120px; } }

@media screen and (min-width: 1020px) and (max-width: 1025px) and (min-height: 1360px) and (max-height: 1370px) {
  .just-ask .hero .social-media {
    display: none; } }

.just-ask .hero .button {
  box-shadow: 15px 15px 25px rgba(112, 61, 21, 0.15);
  color: #EC7532; }
  .just-ask .hero .button__icon {
    color: #FC8B4C; }

@media (max-width: 1079px) {
  .just-ask .hero__title {
    margin-bottom: 60px; } }

.just-ask .character {
  width: 100%;
  height: 100%;
  z-index: 0; }
  @media (max-width: 1079px) {
    .just-ask .character {
      display: none; } }
  .just-ask .character__back-icons1, .just-ask .character__back-icons2, .just-ask .character__back-icons3 {
    display: none; }

@media (min-width: 2000px) {
  .just-ask .hero__title {
    margin-left: -250px; } }

.just-ask .slider {
  display: none;
  opacity: 0; }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: table;
  box-sizing: border-box; }

.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%; }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }
